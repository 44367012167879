import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/dashboard',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },

  //Orders
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/order/Orders.vue')
  },
  {
    path: '/order/add',
    name: 'CreateOrder',
    component: () => import('../views/order/CreateOrder.vue')
  },
  {
    path: '/order/:id',
    name: 'Order',
    component: () => import('../views/order/Order.vue')
  },

  //Services
  {
    path: '/services',
    name: 'Services',
    component: () => import('../views/service/Services.vue')
  },
  {
    path: '/services/add',
    name: 'CreateService',
    component: () => import('../views/service/CreateService.vue')
  },
  {
    path: '/service/:id',
    name: 'Service',
    component: () => import('../views/service/Service.vue')
  },

  //Customers
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/customer/Customers.vue')
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: () => import('../views/customer/Customer.vue')
  },

  //Document
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/document/Documents.vue')
  },
  {
    path: '/document/add',
    name: 'CreateDocument',
    component: () => import('../views/document/CreateDocument.vue')
  },

  //Reports
  {
    path: '/report',
    name: 'Report',
    component: () => import('../views/report/Report.vue')
  },
  //Reports
  {
    path: '/report/daily',
    name: 'Daily Report',
    component: () => import('../views/report/DailyReport.vue')
  },
  {
    path: '/report/service',
    name: 'Service Report',
    component: () => import('../views/report/ServiceReport.vue')
  },

  //Settings
  {
    path: '/settings/company',
    name: 'CompanySettings',
    component: () => import('../views/setting/CompanySettings.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
